import { FilterKeys } from 'config/settings/constants';

export default [
  FilterKeys.frmtP2PRules, FilterKeys.FRMTSuspiciousRules, FilterKeys.games, FilterKeys.table, FilterKeys.studioTable,
  FilterKeys.activeRouletteTable, FilterKeys.partnerTags, FilterKeys.partnerType, FilterKeys.riskAssessmentRuleType,
  FilterKeys.combinationType, FilterKeys.paymentSystems, FilterKeys.activePartners, FilterKeys.ggrCategoryAllGames,
  FilterKeys.fraudAlertTriggers, FilterKeys.gamingMarginAlertGameType, FilterKeys.providers, FilterKeys.shuffler,
  FilterKeys.financeCategory, FilterKeys.gameStyleCategory, FilterKeys.additionalCategory, FilterKeys.changeType,
  FilterKeys.partners, FilterKeys.product, FilterKeys.betType, FilterKeys.srmtPartners, FilterKeys.categoryType,
  FilterKeys.hall, FilterKeys.customAlertPartners, FilterKeys.customAlertSettings, FilterKeys.customAlertGroups,
  FilterKeys.crmtEnabledPartner, FilterKeys.studio, FilterKeys.supportedLicenses, FilterKeys.gameCategory,
  FilterKeys.customGameAlertSettings, FilterKeys.ggrGameCategory, FilterKeys.cumulativeLossGameCategories,
  FilterKeys.clientSportsBookProfile, FilterKeys.rouletteStatisticsTypes, FilterKeys.frmtAmlCombineRules,
  FilterKeys.gameType, FilterKeys.customGameCategories, FilterKeys.licenseType, FilterKeys.frmtAmlRules,
  FilterKeys.sportTypes, FilterKeys.gamingMarginAlertGameCategory, FilterKeys.gamingMarginAlertSelection
];
