/**
 * @flow
 * */
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import Button from '@geneui/components/Button';
import Modal from '@geneui/components/Modal';

import AppSettingsPopupViewModeItem from 'views/components/AppSettingsSuggestionPopup/AppSettingsPopupViewModeItem';
import { buttonConfig, modalConfig, positions } from 'config/settings/core-ui-strings';
import { userSelector } from 'modules/Dashboard/Auth/store/selectors/userSelectors';
import { appSettingsSelector } from 'store/selectors/appSettingsSelectors';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import { updateAppSettings } from 'store/actions/appSettings/actions';
import { setUserSettings } from 'store/actions/userSettings/actions';
import type { AppSettingsSuggestionViewModeType } from 'types';
import { ViewModes } from 'config/settings/constants';

import './styles.scss';

/**
 *
 * @returns {JSX.Element} - React.Component
 */

const AppSettingsSuggestionPopup = () => {
  const {
    state,
    dispatch
  } = useContainerStatusHandler({ selector: appSettingsSelector });
  const userState = useSelector(userSelector);

  const updateViewMode = useCallback((save) => {
    const data = { ...state.data, isSizeChecked: true, userId: userState?.Id };
    if (save) {
      data.viewMode = 'small';
    }
    dispatch(updateAppSettings(data));
    dispatch(setUserSettings({ scheme: JSON.stringify(data), name: 'appSettings' }));
  }, [dispatch, userState, state]);
  const modelsList : Array<AppSettingsSuggestionViewModeType> = [
    {
      key: 'small',
      size: 12,
      title: ViewModes.small,
      disabled: false
    },
    {
      key: 'normal',
      size: 14,
      title: ViewModes.normal,
      disabled: true
    },
    {
      key: 'large',
      size: 16,
      title: ViewModes.large,
      disabled: true
    }
  ];

  return (
    <Modal
      background={modalConfig.background.light}
      className="settings-popup-container"
      closeOnClickOutside={false}
      position={positions.center}
      cancelText="Cancel"
      title="View Mode"
      closable={false}
      width="60rem"
      withPortal
      visible
      footer={(
        <>
          <Button
            appearance={buttonConfig.appearance.minimal}
            onClick={() => updateViewMode(false)}
          >Cancel
          </Button>
          <Button
            appearance={buttonConfig.appearance.default}
            onClick={() => updateViewMode(true)}
            color={buttonConfig.color.primary}
          >Save
          </Button>
        </>
      )}
    >
      <div className="app-settings-popup-container">
        <div className="app-settings-popup-title">
          For better resolution you can change the view mode to small.
        </div>
        <div className="app-settings-modes-container">
          {
            modelsList.map(
              (mode: AppSettingsSuggestionViewModeType) => (
                <AppSettingsPopupViewModeItem
                  isSelected={mode.key === 'small'}
                  disabled={mode.disabled}
                  textSize={mode.size}
                  title={mode.title}
                  key={mode.key}
                />
              )
            )
          }
        </div>
      </div>
    </Modal>
  );
};

AppSettingsSuggestionPopup.displayName = 'AppSettingsSuggestionPopup';

export default AppSettingsSuggestionPopup;
