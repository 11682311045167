/**
 * @flow
 * */
import { put, takeLatest } from 'redux-saga/effects';

import ACTIONS from 'modules/LCRMT/Alerts/store/actions/alerts/hourlyAlertsActions/types';
import {
  setFetchHourlyAlertsReviewHistoryFailure,
  setFetchHourlyAlertsReviewHistorySuccess,
  setFetchHourlyAlertsReviewActionSuccess,
  setFetchHourlyAlertsReviewActionFailure,
  setFetchHourlyAlertsReviewExpandSuccess,
  setFetchHourlyAlertsReviewExpandFailure,
  setFetchHourlyAlertsReviewFailure,
  setFetchHourlyAlertsReviewSuccess,
  setFetchHourlyAlertsExpandSuccess,
  setFetchHourlyAlertsExpandFailure,
  fetchHourlyAlertTabDataFailure,
  fetchHourlyAlertTabDataSuccess,
  setFetchStatisticsSuccess,
  setFetchStatisticsFailure,
  setFetchTrueCountSuccess,
  setFetchTrueCountFailure
} from 'modules/LCRMT/Alerts/store/actions/alerts/hourlyAlertsActions';
import alertsSDK from '../sdk/AlertsSDK';

class HourlyAlertsController {
  static* fetchHourlyAlerts({ payload }) {
    const { tabKey, filters } = payload;
    const { passTotalCount } = filters;
    try {
      const { data, totalCount } = yield alertsSDK.getHourlyAlerts({ filters, tabKey });
      yield put(fetchHourlyAlertTabDataSuccess({ data, tabKey, totalCount, passTotalCount }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(fetchHourlyAlertTabDataFailure({ tabKey, error: err.message }));
    }
  }

  static* fetchHourlyAlertsExpand({ payload }) {
    const { tabKey, alertId, index } = payload;
    try {
      const { data } = yield alertsSDK.GetHourlyAlertsExpand({ alertId });
      yield put(setFetchHourlyAlertsExpandSuccess({ tabKey, data, index }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchHourlyAlertsExpandFailure({ tabKey, index, error: err.message }));
    }
  }

  static* fetchHourlyAlertsReview({ payload }) {
    const { alertId, tabKey, alertType } = payload;
    try {
      const { data } = yield alertsSDK.GetHourlyAlertsReview({ alertId, alertType });
      yield put(setFetchHourlyAlertsReviewSuccess({ tabKey, data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchHourlyAlertsReviewFailure({ tabKey, error: err.message }));
    }
  }

  static* fetchHourlyAlertsReviewAction({ payload }) {
    const { params } = payload;
    const { tabKey, alertId, comment, assessment } = params;
    try {
      const { data } = yield alertsSDK.UpdateHourlyAlertsReviewAction({ alertId, comment, assessment });
      yield put(setFetchHourlyAlertsReviewActionSuccess({ tabKey, data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchHourlyAlertsReviewActionFailure({ tabKey, error: err.message }));
    }
  }

  static* fetchHourlyAlertsReviewExpand({ payload }) {
    const { roundId, tableId, clientId, tabKey, index } = payload;
    try {
      const { data } = yield alertsSDK.GetHourlyAlertsReviewExpand({ roundId, tableId, clientId });
      yield put(setFetchHourlyAlertsReviewExpandSuccess({ tabKey, data, index }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchHourlyAlertsReviewExpandFailure({ tabKey, index, error: err.message }));
    }
  }

  static* fetchHourlyAlertsReviewHistory({ payload }) {
    const { alertId, tabKey } = payload;
    try {
      const { data } = yield alertsSDK.GetHourlyAlertsReviewHistory({ alertId, tabKey });
      yield put(setFetchHourlyAlertsReviewHistorySuccess({ tabKey, data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchHourlyAlertsReviewHistoryFailure({ tabKey, error: err.message }));
    }
  }

  static* fetchStatistics({ payload }) {
    const { clientId, tabKey, dates, statisticType } = payload;
    try {
      const { data } = yield alertsSDK.GetStatistics({ clientId, dates, statisticType });
      yield put(setFetchStatisticsSuccess({ tabKey, data, statisticType }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchStatisticsFailure({ tabKey, statisticType, error: err.message }));
    }
  }

  static* fetchTrueCount({ payload }) {
    const { alertId, tabKey } = payload;
    try {
      const { data } = yield alertsSDK.GetHourlyAlertsTrueCount({ alertId });
      yield put(setFetchTrueCountSuccess({ tabKey, data }));
    } catch (err) {
      if (err.name === 'AbortError') return;
      yield put(setFetchTrueCountFailure({ tabKey, error: err.message }));
    }
  }

  static* cancelHourlyAlertSDKCalls({ payload }) {
    const { name } = payload;
    yield alertsSDK.cancelAlertSDKCalls({ name });
  }

  static* actionsWatcher(): Object {
    yield takeLatest(ACTIONS.FETCH_LCRMT_HOURLY_ALERTS, HourlyAlertsController.fetchHourlyAlerts);
    yield takeLatest(ACTIONS.FETCH_LCRMT_HOURLY_ALERTS_EXPAND, HourlyAlertsController.fetchHourlyAlertsExpand);
    yield takeLatest(ACTIONS.FETCH_LCRMT_HOURLY_ALERTS_REVIEW, HourlyAlertsController.fetchHourlyAlertsReview);
    yield takeLatest(ACTIONS.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_ACTION, HourlyAlertsController.fetchHourlyAlertsReviewAction);
    yield takeLatest(ACTIONS.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_EXPAND, HourlyAlertsController.fetchHourlyAlertsReviewExpand);
    yield takeLatest(ACTIONS.FETCH_LCRMT_HOURLY_ALERTS_REVIEW_HISTORY, HourlyAlertsController.fetchHourlyAlertsReviewHistory);
    yield takeLatest(ACTIONS.FETCH_STATISTICS, HourlyAlertsController.fetchStatistics);
    yield takeLatest(ACTIONS.FETCH_TRUE_COUNT, HourlyAlertsController.fetchTrueCount);
    yield takeLatest(ACTIONS.CANCEL_HOURLY_ALERT_SDK_CALL, HourlyAlertsController.cancelHourlyAlertSDKCalls);
  }
}

export default HourlyAlertsController.actionsWatcher;
