import type { Action } from 'lib/core/flow';

import PLAYER_ACTIONS from './types';
// Global KPIs
export const fetchGlobalKPIs = ({ playerId }) => ({
  type: PLAYER_ACTIONS.FETCH_GLOBAL_KPIS,
  payload: { playerId }
});
export const setFetchGlobalKPIsSuccess = ({ data }) => ({
  type: PLAYER_ACTIONS.SET_FETCH_GLOBAL_KPIS_SUCCESS,
  payload: { data }
});
export const setFetchGlobalKPIsFailure = (error) => ({
  type: PLAYER_ACTIONS.SET_FETCH_GLOBAL_KPIS_FAILURE,
  payload: { error }
});
export const clearGlobalKPIs = () => ({
  type: PLAYER_ACTIONS.CLEAR_GLOBAL_KPIS_STATE
});

// ClientList
export const fetchPlayers = ({ appendData = false, count, start, passTotalCount, ...filters }): Action => ({
  type: PLAYER_ACTIONS.FETCH_PLAYERS,
  payload: { appendData, count, start, passTotalCount, ...filters }
});
export const setFetchPlayersSuccess = ({ data, totalCount, passTotalCount }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYERS_SUCCESS,
  payload: { data, totalCount, passTotalCount }
});
export const setFetchPlayersAppendSuccess = ({ data, totalCount }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYERS_APPEND_SUCCESS,
  payload: { data, totalCount }
});
export const setFetchPlayersFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_PLAYERS_FAILURE,
  payload: { error }
});
export const clearPlayersState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_PLAYERS_STATE
});

// Monitoring Existing Alert
export const fetchExistingAlert = ({ playerId, projectType, licence = null }): Action => ({
  type: PLAYER_ACTIONS.FETCH_EXISTING_ALERT,
  payload: { playerId, projectType, licence }
});
export const setFetchExistingAlertSuccess = ({ data }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_EXISTING_ALERT_SUCCESS,
  payload: { data }
});
export const setFetchExistingAlertFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_EXISTING_ALERT_FAILURE,
  payload: { error }
});
export const clearExistingAlertState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_EXISTING_ALERT_STATE
});

// Monitoring Set Alert
export const fetchSetAlert = ({ data, projectType }): Action => ({
  type: PLAYER_ACTIONS.FETCH_SET_ALERT,
  payload: { data, projectType }
});
export const setFetchSetAlertSuccess = (): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_SET_ALERT_SUCCESS
});
export const setFetchSetAlertFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_SET_ALERT_FAILURE,
  payload: { error }
});
export const clearSetAlertState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_SET_ALERT_STATE
});

// Remove Top Priority
export const fetchRemoveTopPriority = ({ playerId, state }): Action => ({
  type: PLAYER_ACTIONS.FETCH_REMOVE_TOP_PRIORITY,
  payload: { playerId, state }
});
export const setFetchRemoveTopPrioritySuccess = (): Action => ({
  type: PLAYER_ACTIONS.SET_FORCE_REMOVE_TOP_PRIORITY_SUCCESS
});
export const setFetchRemoveTopPriorityFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FORCE_REMOVE_TOP_PRIORITY_FAILURE,
  payload: { error }
});
export const clearRemoveTopPriorityState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_FORCE_REMOVE_TOP_PRIORITY_STATE
});

// set and edit Top Priority
export const fetchSetTopPriority = ({ limit, preMatchLimit, delay, playerId, clientSportsBookProfileId, sportsBookProfileName, comment, state }): Action => ({
  type: PLAYER_ACTIONS.FETCH_SET_TOP_PRIORITY,
  payload: { limit, preMatchLimit, delay, playerId, clientSportsBookProfileId, sportsBookProfileName, comment, state }
});
export const setFetchSetTopPrioritySuccess = (): Action => ({
  type: PLAYER_ACTIONS.SET_FORCE_SET_TOP_PRIORITY_SUCCESS
});
export const setFetchSetTopPriorityFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FORCE_SET_TOP_PRIORITY_FAILURE,
  payload: { error }
});
export const clearSetTopPriorityState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_FORCE_SET_TOP_PRIORITY_STATE
});

// Monitoring Resolve Alert
export const fetchResolveAlert = ({ filters, projectType }): Action => ({
  type: PLAYER_ACTIONS.FETCH_RESOLVE_ALERT,
  payload: { filters, projectType }
});
export const setFetchResolveAlertSuccess = (): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_RESOLVE_ALERT_SUCCESS
});
export const setFetchResolveAlertFailure = ({ error }): Action => ({
  type: PLAYER_ACTIONS.SET_FETCH_RESOLVE_ALERT_FAILURE,
  payload: { error }
});
export const clearResolveAlertState = (): Action => ({
  type: PLAYER_ACTIONS.CLEAR_RESOLVE_ALERT_STATE
});

// cancel call
export const cancelPlayerSdkCall = ({ name }): Action => ({
  type: PLAYER_ACTIONS.CANCEL_PLAYER_SDK_CALL,
  payload: { name }
});

export * from './categoryActions';
export * from './financialActions';
export * from './generalActions';
export * from './noteActions';
export * from './restrictionActions';
export * from './alertActions';
