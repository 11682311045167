/**
 * @flow
 * */
import React from 'react';
import Dropdown from '@geneui/components/Dropdown';
import Title from '@geneui/components/Title';

import { inputConfig, titleConfig } from 'config/settings/core-ui-strings';
import { ViewModes } from 'config/settings/constants';
import type { AppSettingsViewModeType } from 'types';

import AppSettingsPopupViewModeItem from './AppSettingsPopupViewModeItem';

import './styles.scss';

const defaultDropdownConfigs = {
  labelAppearance: inputConfig.labelAppearance.swap,
  flexibility: inputConfig.flexibility.fullWidth,
  inputSize: inputConfig.size.big,
  size: inputConfig.size.big,
  isMultiSelect: false,
  clearable: false
};

type Props = {
  setTimeZoneValue: Function,
  timeZones: Array<any>,
  setViewMode: Function,
  timeZoneValue: string,
  selectedMode: string
};

/**
 *
 * @param {string} selectedMode - default value is
 * @param {string} timeZoneValue - timeZoneValue
 * @param {Array} timeZones - timeZones
 * @param {function} setViewMode - set Selected
 * @param {function} setTimeZoneValue - setTimeZoneValue
 * @returns {JSX.Element} - React.Component
 */
const AppSettingsPopup = ({ selectedMode, setViewMode, timeZoneValue, setTimeZoneValue, timeZones }: Props) => {
  const modelsList : Array<AppSettingsViewModeType> = [
    {
      key: 'small',
      size: '1.2rem',
      title: ViewModes.small
    },
    {
      key: 'normal',
      size: '1.4rem',
      title: ViewModes.normal
    },
    {
      key: 'large',
      size: '1.6rem',
      title: ViewModes.large
    }
  ];

  return (
    <div className="app-settings-popup-container">
      <div className="app-settings-popup-time-zone">
        <Dropdown
          onChange={(event) => setTimeZoneValue(event.Id)}
          {...defaultDropdownConfigs}
          placeholder="Time Zone"
          labelKey="DisplayName"
          value={timeZoneValue}
          data={timeZones}
          valueKey="Id"
          isValid
        />
      </div>
      <Title withLine text="View Mode" color={titleConfig.color.base} className="app-settings-title" />
      <div className="app-settings-modes-container">
        {
          modelsList.map(
            (mode: AppSettingsViewModeType) => (
              <AppSettingsPopupViewModeItem
                isSelected={selectedMode === mode.key}
                setSelected={setViewMode}
                textSize={mode.size}
                title={mode.title}
                key={mode.key}
                id={mode.key}
              />
            )
          )
        }
      </div>
    </div>
  );
};

AppSettingsPopup.displayName = 'AppSettingsPopup';

export default AppSettingsPopup;
