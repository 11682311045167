import SETTINGS_ROUTES from './Settings/routes';
import ALERT_ROUTES from './AlertMonitor/routes';
import CLIENT_ROUTES from './Clients/routes';

const routes = [
  ...SETTINGS_ROUTES,
  ...ALERT_ROUTES,
  ...CLIENT_ROUTES
];

export {
  routes
};
