/**
 * @flow
 * */
import createSymbol, { createInternalSymbol } from 'lib/core/createSymbol';

const createPlayerGroupSymbol = createSymbol('PLAYER_GROUP');
const createPlayerGroupInternalSymbol = createInternalSymbol('PLAYER_GROUP');

export default {
  // Player Groups
  FETCH_PLAYER_GROUPS: createPlayerGroupSymbol('FETCH_PLAYER_GROUPS'),
  SET_FETCH_PLAYER_GROUPS_SUCCESS: createPlayerGroupSymbol('SET_FETCH_PLAYER_GROUPS_SUCCESS'),
  SET_FETCH_PLAYER_GROUPS_FAILURE: createPlayerGroupSymbol('SET_FETCH_FETCH_PLAYER_GROUPS_FAILURE'),
  CLEAR_PLAYER_GROUPS_STATE: createPlayerGroupInternalSymbol('CLEAR_PLAYER_GROUPS_STATE'),

  // Add Player Group
  ADD_GROUP: createPlayerGroupSymbol('ADD_GROUP'),
  SET_ADD_GROUP_SUCCESS: createPlayerGroupSymbol('SET_ADD_GROUP_SUCCESS'),
  SET_ADD_GROUP_FAILURE: createPlayerGroupSymbol('SET_ADD_GROUP_FAILURE'),
  CLEAR_ADD_GROUP_STATE: createPlayerGroupInternalSymbol('CLEAR_ADD_GROUP_STATE'),

  // Fetch Group Info
  FETCH_GROUP_INFO: createPlayerGroupSymbol('GET_GROUP_INFO'),
  SET_GROUP_INFO_SUCCESS: createPlayerGroupSymbol('SET_GROUP_INFO_SUCCESS'),
  SET_GROUP_INFO_FAILURE: createPlayerGroupSymbol('SET_GROUP_INFO_FAILURE'),
  CLEAR_GROUP_INFO_STATE: createPlayerGroupInternalSymbol('CLEAR_GROUP_INFO_STATE'),

  // Update Player Group
  UPDATE_GROUP: createPlayerGroupSymbol('UPDATE_GROUP'),
  SET_UPDATE_GROUP_SUCCESS: createPlayerGroupSymbol('SET_UPDATE_GROUP_SUCCESS'),
  SET_UPDATE_GROUP_FAILURE: createPlayerGroupSymbol('SET_UPDATE_GROUP_FAILURE'),
  CLEAR_UPDATE_GROUP_STATE: createPlayerGroupInternalSymbol('CLEAR_UPDATE_GROUP_STATE'),

  // Delete Player Group
  DELETE_GROUP: createPlayerGroupSymbol('DELETE_GROUP'),
  SET_DELETE_GROUP_SUCCESS: createPlayerGroupSymbol('SET_DELETE_GROUP_SUCCESS'),
  SET_DELETE_GROUP_FAILURE: createPlayerGroupSymbol('SET_DELETE_GROUP_FAILURE'),
  CLEAR_DELETE_GROUP_STATE: createPlayerGroupInternalSymbol('CLEAR_DELETE_GROUP_STATE'),

  // Clients List
  FETCH_PLAYERS: createPlayerGroupSymbol('FETCH_PLAYERS'),
  SET_FETCH_PLAYERS_SUCCESS: createPlayerGroupSymbol('SET_FETCH_PLAYERS_SUCCESS'),
  SET_FETCH_PLAYERS_FAILURE: createPlayerGroupSymbol('SET_FETCH_PLAYERS_FAILURE'),
  CLEAR_PLAYERS_STATE: createPlayerGroupInternalSymbol('CLEAR_PLAYERS_STATE'),

  // Groups Clients
  FETCH_GROUP_PLAYERS: createPlayerGroupSymbol('FETCH_GROUP_PLAYERS'),
  SET_FETCH_GROUP_PLAYERS_SUCCESS: createPlayerGroupSymbol('SET_FETCH_GROUP_PLAYERS_SUCCESS'),
  SET_FETCH_GROUP_PLAYERS_FAILURE: createPlayerGroupSymbol('SET_FETCH_GROUP_PLAYERS_FAILURE'),
  CLEAR_GROUP_PLAYERS_STATE: createPlayerGroupInternalSymbol('CLEAR_GROUP_PLAYERS_STATE'),

  // Add Player to Group
  ADD_PLAYER_TO_GROUP: createPlayerGroupSymbol('ADD_PLAYER_TO_GROUP'),
  SET_ADD_PLAYER_TO_GROUP_SUCCESS: createPlayerGroupSymbol('SET_ADD_PLAYER_TO_GROUP_SUCCESS'),
  SET_ADD_PLAYER_TO_GROUP_FAILURE: createPlayerGroupSymbol('SET_ADD_PLAYER_TO_GROUP_FAILURE'),
  CLEAR_ADD_PLAYER_TO_GROUP_STATE: createPlayerGroupInternalSymbol('CLEAR_ADD_PLAYER_TO_GROUP_STATE'),

  // Add Player to Group List
  ADD_PLAYER_TO_GROUP_LIST: createPlayerGroupSymbol('ADD_PLAYER_TO_GROUP_LIST'),

  // Delete Player to Group
  DELETE_PLAYER_TO_GROUP_LIST: createPlayerGroupSymbol('DELETE_PLAYER_TO_GROUP_LIST'),

  // Delete Player From Group
  DELETE_PLAYER_FROM_GROUP: createPlayerGroupSymbol('DELETE_PLAYER_FROM_GROUP'),
  SET_DELETE_PLAYER_FROM_GROUP_SUCCESS: createPlayerGroupSymbol('SET_DELETE_PLAYER_FROM_GROUP_SUCCESS'),
  SET_DELETE_PLAYER_FROM_GROUP_FAILURE: createPlayerGroupSymbol('SET_DELETE_PLAYER_FROM_GROUP_FAILURE'),
  CLEAR_DELETE_PLAYER_FROM_GROUP_STATE: createPlayerGroupInternalSymbol('CLEAR_DELETE_PLAYER_FROM_GROUP_STATE'),

  // Group Action
  GROUP_ACTION: createPlayerGroupSymbol('GROUP_ACTION'),
  SET_GROUP_ACTION_SUCCESS: createPlayerGroupSymbol('SET_GROUP_ACTION_SUCCESS'),
  SET_GROUP_ACTION_FAILURE: createPlayerGroupSymbol('SET_GROUP_ACTION_FAILURE'),
  CLEAR_GROUP_ACTION_STATE: createPlayerGroupInternalSymbol('CLEAR_GROUP_ACTION_STATE'),

  // UPLOAD FILE
  UPLOAD_FILE: createPlayerGroupSymbol('UPLOAD_FILE'),
  SET_UPLOAD_FILE_SUCCESS: createPlayerGroupSymbol('SET_UPLOAD_FILE_SUCCESS'),
  SET_UPLOAD_FILE_FAILURE: createPlayerGroupSymbol('SET_UPLOAD_FILE_FAILURE'),
  CLEAR_UPLOAD_FILE_STATE: createPlayerGroupInternalSymbol('CLEAR_UPLOAD_FILE_STATE'),

  // PlayerGroup SDK cancel
  CANCEL_PLAYER_GROUP_SDK_CALL: createPlayerGroupSymbol('CANCEL_PLAYER_GROUP_SDK_CALL')
};
