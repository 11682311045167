/**
 * @flow
 * */
import { ACTION_STATUSES } from 'lib/core/actionStatuses';
import {
  initialPlayerSessionApiState,
  initialSectionWithCountState,
  initialSectionWithoutData,
  initialSectionState
} from 'store/reducers/initialStates';
import type { PlayerState } from 'modules/SRMT/Players/types';
import PLAYER_ACTION_TYPES from '../actions/player/types';

const initialState = {
  sessionIps: initialPlayerSessionApiState(),
  multiAccounts: initialSectionState(),
  playerInfo: initialSectionState(),
  setMonitoringAlert: initialSectionState(),
  checkedManually: initialSectionWithoutData(),
  checkHistory: initialSectionWithCountState(),
  resolveMonitoringAlert: initialSectionWithoutData(),
  fingerprints: initialSectionState()
};

const actionManager = {
  // Multi Accounts
  [PLAYER_ACTION_TYPES.FETCH_MULTI_ACCOUNTS]: (state: PlayerState): PlayerState => ({
    ...state,
    multiAccounts: {
      ...state.multiAccounts,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_MULTI_ACCOUNTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    multiAccounts: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_MULTI_ACCOUNTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    multiAccounts: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_MULTI_ACCOUNTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    multiAccounts: initialSectionState()
  }),

  // Player Info
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_INFO]: (state: PlayerState) => ({
    ...state,
    playerInfo: {
      data: null,
      status: ACTION_STATUSES.PENDING,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_INFO_SUCCESS]: (state: PlayerState, payload) => ({
    ...state,
    playerInfo: {
      data: payload.data,
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_INFO_FAILURE]: (state: PlayerState, payload) => ({
    ...state,
    playerInfo: {
      data: null,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.UPDATE_PLAYER_INFO]: (state: PlayerState, payload) => ({
    ...state,
    playerInfo: {
      data: {
        ...state.playerInfo.data,
        ...payload.params
      },
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_INFO_STATE]: (state: PlayerState) => ({
    ...state,
    playerInfo: initialSectionState()
  }),

  // Player Session ips
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_SESSION_IPS]: (state: PlayerState): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_SESSION_IPS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_SESSION_IPS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_SESSION_IPS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    sessionIps: initialPlayerSessionApiState()
  }),

  // Player All Session ips
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_ALL_SESSION_IPS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      allSessionIpsData: {
        ...state.sessionIps.allSessionIpsData,
        [payload.loginIp]: {
          status: ACTION_STATUSES.PENDING,
          data: null,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_All_SESSION_IPS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      allSessionIpsData: {
        ...state.sessionIps.allSessionIpsData,
        [payload.loginIp]: {
          status: ACTION_STATUSES.SUCCEED,
          data: payload.data,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_All_SESSION_IPS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      allSessionIpsData: {
        ...state.sessionIps.allSessionIpsData,
        [payload.loginIp]: {
          status: ACTION_STATUSES.FAILED,
          data: null,
          error: payload.error
        }
      }
    }
  }),

  // All Clients Session ip
  [PLAYER_ACTION_TYPES.FETCH_ALL_PLAYERS_SESSION_IP]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      allPlayersIpData: {
        ...state.sessionIps.allPlayersIpData,
        [payload.loginIp]: {
          status: ACTION_STATUSES.PENDING,
          data: null,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_All_PLAYERS_SESSION_IP_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      allPlayersIpData: {
        ...state.sessionIps.allPlayersIpData,
        [payload.loginIp]: {
          status: ACTION_STATUSES.SUCCEED,
          data: payload.data,
          error: null
        }
      }
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_All_PLAYERS_SESSION_IP_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    sessionIps: {
      ...state.sessionIps,
      allPlayersIpData: {
        ...state.sessionIps.allPlayersIpData,
        [payload.loginIp]: {
          status: ACTION_STATUSES.FAILED,
          data: null,
          error: payload.error
        }
      }
    }
  }),

  // Player Fingerprints
  [PLAYER_ACTION_TYPES.FETCH_PLAYER_FINGERPRINTS]: (state: PlayerState): PlayerState => ({
    ...state,
    fingerprints: {
      ...state.fingerprints,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINGERPRINTS_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    fingerprints: {
      ...state.fingerprints,
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_PLAYER_FINGERPRINTS_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    fingerprints: {
      ...state.fingerprints,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_PLAYER_FINGERPRINTS_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    fingerprints: initialSectionState()
  }),

  // Player Set Monitoring Alert
  [PLAYER_ACTION_TYPES.FETCH_SET_MONITORING_ALERT]: (state: PlayerState): PlayerState => ({
    ...state,
    setMonitoringAlert: {
      ...state.setMonitoringAlert,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_SET_MONITORING_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    setMonitoringAlert: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_SET_MONITORING_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    setMonitoringAlert: {
      ...state.setMonitoringAlert,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_SET_MONITORING_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    setMonitoringAlert: initialSectionState()
  }),

  // Player Resolve Monitoring Alert
  [PLAYER_ACTION_TYPES.FETCH_RESOLVE_ALERT]: (state: PlayerState): PlayerState => ({
    ...state,
    resolveMonitoringAlert: {
      ...state.resolveMonitoringAlert,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_RESOLVE_ALERT_SUCCESS]: (state: PlayerState): PlayerState => ({
    ...state,
    resolveMonitoringAlert: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_RESOLVE_ALERT_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    resolveMonitoringAlert: {
      ...state.resolveMonitoringAlert,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_RESOLVE_ALERT_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    resolveMonitoringAlert: initialSectionWithoutData()
  }),

  // Player Check Manually
  [PLAYER_ACTION_TYPES.FETCH_SET_CHECK_MANUALLY]: (state: PlayerState): PlayerState => ({
    ...state,
    checkedManually: {
      ...state.checkedManually,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_SET_CHECK_MANUALLY_SUCCESS]: (state: PlayerState): PlayerState => ({
    ...state,
    checkedManually: {
      status: ACTION_STATUSES.SUCCEED,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_SET_CHECK_MANUALLY_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    checkedManually: {
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_FETCH_SET_CHECK_MANUALLY_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    checkedManually: initialSectionWithoutData()
  }),
  // Check History
  [PLAYER_ACTION_TYPES.FETCH_CHECK_HISTORY]: (state: PlayerState): PlayerState => ({
    ...state,
    checkHistory: {
      ...state.checkHistory,
      status: ACTION_STATUSES.PENDING
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_CHECK_HISTORY_SUCCESS]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    checkHistory: {
      status: ACTION_STATUSES.SUCCEED,
      data: payload.data,
      totalCount: !payload.passTotalCount ? payload.totalCount : state.checkHistory.totalCount,
      error: null
    }
  }),
  [PLAYER_ACTION_TYPES.SET_FETCH_CHECK_HISTORY_FAILURE]: (state: PlayerState, payload): PlayerState => ({
    ...state,
    checkHistory: {
      ...state.checkHistory,
      status: ACTION_STATUSES.FAILED,
      error: payload.error
    }
  }),
  [PLAYER_ACTION_TYPES.CLEAR_FETCH_CHECK_HISTORY_STATE]: (state: PlayerState): PlayerState => ({
    ...state,
    checkHistory: initialSectionWithCountState()
  })
};

export default {
  actionManager,
  initialState
};
