/**
 * @flow
 * */
import React, { useCallback, useState } from 'react';
import Dropdown from '@geneui/components/Dropdown';
import Button from '@geneui/components/Button';
import Modal from '@geneui/components/Modal';

import { positions, buttonConfig, inputConfig } from 'config/settings/core-ui-strings';

import './styles.scss';

type Props = {
  partners: Array<{Id: number, Name:string}>,
  continueHandler: Function,
  setModalIsOpen: Function,
  modalIsOpen: boolean
}

const defaultDropDownConfigs = {
  labelAppearance: inputConfig.labelAppearance.swap,
  flexibility: inputConfig.flexibility.fullWidth,
  inputSize: inputConfig.size.big,
  size: inputConfig.size.big,
  isMultiSelect: false,
  clearable: true
};

/**
 * ChoosePartnerModal
 * @param {boolean} modalIsOpen -
 * @param {Function} continueHandler -
 * @param {setModalIsOpen} setModalIsOpen -
 * @param {Array<Object>} partners -
 * @returns {JSX.Element} -
 * @constructor
 */
const ChoosePartnerModal = ({ modalIsOpen, continueHandler, setModalIsOpen, partners }: Props) => {
  const [isSelected, setSelected] = useState(null);

  const cancelHandler = useCallback(() => {
    setModalIsOpen(false);
  }, [setModalIsOpen]);

  return modalIsOpen && (
    <Modal
      className="choose-partner-modal"
      closeOnClickOutside={false}
      position={positions.center}
      title="N/A (Turnkey)"
      visible={modalIsOpen}
      closable={false}
      width="42rem"
      footer={(
        <>
          <Button
            appearance={buttonConfig.appearance.minimal}
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            appearance={buttonConfig.appearance.default}
            color={buttonConfig.color.confirm}
            onClick={() => continueHandler(isSelected)}
            disabled={!isSelected}
          >
            Continue
          </Button>
        </>
      )}
      withPortal
    >
      <Dropdown
        data={partners.map(i => ({ ...i, Name: `${i.Name} / ${i.Id}` })) || []}
        onChange={(e) => setSelected(e?.Id)}
        onClear={() => setSelected(null)}
        placeholder="Choose Partner"
        {...defaultDropDownConfigs}
        value={isSelected}
        labelKey="Name"
        valueKey="Id"
        required
        isValid
      />
    </Modal>
  );
};

ChoosePartnerModal.displayName = 'ChoosePartnerModal';

export default ChoosePartnerModal;
