import React from 'react';
import Link from 'react-router-dom/es/Link';

import { permissions } from 'config/settings/permissions';
import settings from 'config/constants';

const products = settings.PRODUCTS;

const { path } = products.FRMT;

export default [
  {
    title: <Link className="route_link" to={`/${path}/alert-monitor`}>Alert Monitor</Link>,
    id: `/${path}/alert-monitor`,
    icon: 'bc-icon-alert-monitor-rmt-36',
    permission: permissions.FRMTAlertMonitor
  },
  {
    title: <Link className="route_link" to={`/${path}/clients`}>Clients</Link>,
    id: `/${path}/clients`,
    icon: 'bc-icon-players-rmt-36',
    permission: permissions.FRMTClientsListAndIndividualPage
  },
  {
    id: `/${path}/settings`,
    title: 'Settings',
    icon: 'bc-icon-settings',
    permission: permissions.FRMTSettings,
    data: [
      {
        id: `/${path}/settings/alert-settings`,
        title: <Link to={`/${path}/settings/alert-settings`}>Alert Settings</Link>,
        permission: permissions.FRMTSettings
      }
    ]
  }
];
