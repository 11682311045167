import CacheService from 'core/services/storage/CacheService';
import cachedFilterKeys from 'hooks/Filter/cachedFilterKeys';

export const filterCaching = async (data) => {
  const filterCache = await CacheService.getIndexDbFilter();
  cachedFilterKeys.forEach(item => {
    if (data[item] && !filterCache[item]) {
      filterCache[item] = data[item];
    }
  });
  CacheService.setIndexDbFilter({ ...filterCache });
};

export const reduceKeys = async (data) => {
  const filterCache = await CacheService.getIndexDbFilter();
  if (!filterCache) {
    return data;
  }
  const newData = [];
  data.forEach(item => {
    if (!filterCache[item]) {
      newData.push(item);
    }
  });
  return newData;
};
