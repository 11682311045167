/**
 * @flow
 * */
import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Toaster from '@geneui/components/Toaster';

import { internetStatusSelector, appStateSelector } from 'store/selectors/applicationSelectors';
import { appSettingsUpdateSelector } from 'store/selectors/appSettingsSelectors';
import useContainerStatusHandler from 'hooks/useContainerStatusHandler';
import { allFiltersSelector } from 'store/selectors/filterSelectors';
import InternetStatusListener from 'hooks/internetStatusListener';
import HeaderContainer from 'views/containers/HeaderContainer';
import { detectAbsentUrl } from 'lib/helpers/detectAbsentUrl';
import CacheService from 'core/services/storage/CacheService';
import Skeleton from 'views/components/loaders/Skeleton';
import useMessageHandler from 'hooks/useMessageHandler';
import { routes, staticRoutes } from 'config/routes';
import messages from 'config/settings/messages';
import { history } from 'config/history';

import className from 'classnames';
import AppLayout from '../AppLayout';
import Routes from '../Router';

import './styles.scss';

const allRoutes = [...staticRoutes, ...routes];

const AppContainer = () => {
  const [refreshKey, setRefreshKey] = useState(null);
  const liveCasinoToken = CacheService.getItem('live_casino_token');

  const [fullScreen, setFullScreen] = useState(null);
  const internetStatus = useSelector(internetStatusSelector);
  const appState = useSelector(appStateSelector);
  const location = useLocation();
  InternetStatusListener();

  const {
    isSucceed
  } = useContainerStatusHandler({ selector: appSettingsUpdateSelector });

  const {
    isFailed
  } = useContainerStatusHandler({ selector: allFiltersSelector });

  useMessageHandler({
    errorMessage: messages.error.somethingWentWrong,
    isFailed
  });

  const messageHandler = useCallback(() => {
    if (internetStatus.isOnLine !== null) {
      if (internetStatus.isOnLine) {
        Toaster.success({
          title: 'Welcome Back',
          toasterPosition: 'left-top',
          message: internetStatus.onLineMessage
        });
      } else {
        Toaster.warning({
          title: 'Warning',
          toasterPosition: 'top-center',
          duration: 10000,
          message: internetStatus.offLineMessage
        });
      }
    }
  }, [internetStatus.isOnLine, internetStatus.onLineMessage, internetStatus.offLineMessage]);

  const checkUrl = useCallback(() => {
    const existing = detectAbsentUrl(location, allRoutes);
    !existing && history.push('/404');
    setFullScreen(!!existing.fullScreen);
  }, [location, setFullScreen]);

  const resetDom = useCallback(() => {
    if (isSucceed) {
      setRefreshKey(Date.now().toString());
    }
  }, [isSucceed]);

  useEffect(messageHandler, [internetStatus]);
  useEffect(checkUrl, [location]);
  useEffect(resetDom, [isSucceed]);

  return (
    <>
      {!liveCasinoToken && appState.isLoggedIn && <HeaderContainer />}
      <Switch>
        <Suspense
          fallback={<Skeleton isFillScreen center />}
        >
          {fullScreen !== null && (
            <div
              key={refreshKey}
              className={`main-wr flex ${className({ 'full-screen': fullScreen })}`}
            >
              <main className="main-content">
                <div className="right-main-container">
                  <Routes routes={staticRoutes} />
                  {/* // $FlowFixMe */}
                  {routes.map(({ page, navigation, name, ...rest }, index) => (
                    <Route
                      /* eslint-disable-next-line react/no-array-index-key */
                      key={index}
                      {...rest}
                      render={() => (
                        <AppLayout
                          page={page}
                          navigation={navigation}
                        />
                      )}
                    />
                  ))}
                </div>
              </main>
            </div>
          )}
        </Suspense>
      </Switch>
      <Toaster
        toasterPosition="right-top"
        notificationPosition="right-top"
      />
    </>
  );
};

export default AppContainer;
