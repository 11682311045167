/**
 * @flow
 * */

import { takeLatest, put } from 'redux-saga/effects';
import FILTERS_ACTION_TYPES from 'store/actions/filter/types';
import { filterCaching, reduceKeys } from 'lib/helpers/filterCaching';
import {
  setFetchFiltersDataSuccess, setFetchFiltersDataFailure, setFetchUserDataSuccess, setFetchUserDataFailure
} from 'store/actions/filter/actions';
import filtersSDK from '../sdk/filters';

class FiltersController {
  static* fetchFilters({ payload }) {
    const { filters, filterParams, caching } = payload;
    try {
      const newFilters = yield reduceKeys(filters);
      if (newFilters.length) {
        const { data } = yield filtersSDK.getFiltersData({ filters: newFilters.length ? newFilters : filters, filterParams, caching });
        filterCaching(data).then();
        yield put(setFetchFiltersDataSuccess(data));
      } else {
        yield put(setFetchFiltersDataSuccess({}));
      }
    } catch (err) {
      yield put(setFetchFiltersDataFailure({ error: err.message }));
    }
  }

  static* fetchFilterUsers({ payload }) {
    const { filters } = payload;
    try {
      const { data } = yield filtersSDK.fetchFilterUsers({ filters });
      yield put(setFetchUserDataSuccess(data));
    } catch (err) {
      yield put(setFetchUserDataFailure({ error: err.message }));
    }
  }

  static* userWatcher<T>(): Iterable<T> {
    yield takeLatest(FILTERS_ACTION_TYPES.FETCH_USERS_DATA, FiltersController.fetchFilterUsers);
    yield takeLatest(FILTERS_ACTION_TYPES.FETCH_FILTERS_DATA, FiltersController.fetchFilters);
  }
}

export default FiltersController.userWatcher;
