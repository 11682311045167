/**
 *  @author => @RMT_FRONT_CORE/CLI
 * */
import React from 'react';
import { Redirect } from 'react-router-dom';
import settings from 'config/constants';

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const AlertSettings = React.lazy(() => import('./views/pages/AlertSettings'));
const TriggerSettings = React.lazy(() => import('./views/pages/TriggerSettings'));

const subUrl = settings.PRODUCTS.CRMT.path;
const modulePath = `${subUrl}/settings`;

const ROUTES: Array<Route> = [
  { path: `/${modulePath}`, name: 'Settings', exact: true, page: () => <Redirect to={`/${modulePath}/alert-settings`} /> },
  { path: `/${modulePath}/alert-settings`, name: 'Alert Settings', exact: true, page: AlertSettings },
  { path: `/${modulePath}/trigger-settings`, name: 'Trigger Settings', exact: true, page: TriggerSettings }
];

export default ROUTES;
