export const statisticTypeEnumById = {
  1: 'gameType',
  2: 'table',
  3: 'dealer'
};
export const statisticTypeEnumByKey = {
  gameType: 1,
  table: 2,
  dealer: 3
};

export const alertTypes = {
  MarginWinners: 1,
  Winners: 2,
  StraightUpWinners: 3,
  OppositeBetting: 4,
  SuspiciousTips: 5,
  gameWinners: 6,
  advantagePlay: 8,
  robotPlay: 9,
  sideBets: 10
};

export const lcrmtNotificationSettingsUrl = {
  7: 'daily-alerts/opposite-betting',
  8: 'daily-alerts/suspicious-tips',
  9: 'hourly-alerts/margin-winners',
  10: 'hourly-alerts/winners',
  11: 'hourly-alerts/straight-up-winners',
  15: 'hourly-alerts/game-winners',
  16: 'daily-alerts/advantage-play/blackjack-main-bets',
  21: 'daily-alerts/advantage-play/blackjack-side-bets'
};
