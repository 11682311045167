/**
 * @flow
 * */
import React from 'react';

import settings from 'config/constants';
import { Redirect } from 'react-router-dom';

const CRMTMultiAccountAlertsMonitor = React.lazy(() => import('./Tabs/MultiAccountAlertsMonitor/index'));
const CRMTAlertMonitorContainer = React.lazy(() => import('./index'));

type Route = {
    path: string,
    name: string,
    exact: boolean,
    page: Function,
    subRoutes?: Array<Route>,
}

const subUrl = settings.PRODUCTS.CRMT.path;
const modulePath = `${subUrl}/alert-monitor`;

const ROUTES: Array<Route> = [
    { path: '/crmt', name: 'crmt', exact: true, page: () => <Redirect to={`/${modulePath}/game-anomalies`} /> },

    { path: `/${modulePath}/game-anomalies`, name: 'Game Anomalies', exact: true, page: () => <Redirect to={`/${modulePath}/game-anomalies/suspicious-ggr`} /> },
    { path: `/${modulePath}/game-anomalies/:tab`, name: 'Tabs', exact: true, page: CRMTAlertMonitorContainer },

    { path: `/${modulePath}/multiAccount`, name: 'Multi Account', exact: true, page: CRMTMultiAccountAlertsMonitor }
];

export default ROUTES;
