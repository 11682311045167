import iconColors from 'config/settings/colors';
import { fraudMonitor, infoMonitor } from 'modules/SRMT/AlertMonitor/settings';

export const groupTypes = {
  create: 'create',
  edit: 'edit'
};
export const ViewModes = {
  small: 'small',
  normal: 'normal',
  large: 'large'
};

export const ViewModesSize = {
  small: 75,
  normal: 100,
  large: 105
};

export const ViewModesFontSize = {
  small: 7.5,
  normal: 10,
  large: 10.5
};

export const playerInfoModalTypes = {
  LastLoginIp: 'LastLoginIp',
  LastFingerprint: 'LastFingerprint',
  LastCheck: 'LastCheck'
};

export const CUSTOM_CATEGORY_ID = 106;

export const GameStylesFromPlatform = [
  {
    Id: 11,
    Name: 'Arbitrage Betting',
    Icon: 'fa fa-plug',
    Color: 'c-11',
    Limit: null,
    Delay: null,
    IsSelected: null,
    SubId: null,
    isChild: false,
    SelectedGameStyleId: null,
    PlayerSubCategories: [
      { Id: 11, Name: 'Arbitrage Betting 1', Icon: 'fa fa-plug', IsSelected: null, SubId: 1, Limit: null, Delay: null },
      { Id: 11, Name: 'Arbitrage Betting 2', Icon: 'fa fa-plug', IsSelected: null, SubId: 2, Limit: null, Delay: null },
      { Id: 11, Name: 'Arbitrage Betting 3', Icon: 'fa fa-plug', IsSelected: null, SubId: 3, Limit: null, Delay: null }
    ]
  },
  {
    Id: 12,
    Name: 'SFM',
    Icon: 'fa fa-users',
    Color: 'c-12',
    Limit: null,
    Delay: null,
    IsSelected: null,
    SubId: null,
    isChild: false,
    SelectedGameStyleId: null,
    PlayerSubCategories: [
      { Id: 12, Name: 'SFM 1', Icon: 'fa fa-users', IsSelected: null, SubId: 1, Limit: null, Delay: null },
      { Id: 12, Name: 'SFM 2', Icon: 'fa fa-users', IsSelected: null, SubId: 2, Limit: null, Delay: null },
      { Id: 12, Name: 'SFM 3', Icon: 'fa fa-users', IsSelected: null, SubId: 3, Limit: null, Delay: null }
    ]
  },
  {
    Id: 25,
    Name: 'Corridor',
    Icon: 'fa fa-exclamation',
    Color: 'c-25',
    Limit: null,
    Delay: null,
    IsSelected: null,
    SubId: null,
    isChild: false,
    SelectedGameStyleId: null,
    PlayerSubCategories: [
      { Id: 25, Name: 'Corridor 1', Icon: 'fa fa-exclamation', IsSelected: null, SubId: 1, Limit: null, Delay: null },
      { Id: 25, Name: 'Corridor 2', Icon: 'fa fa-exclamation', IsSelected: null, SubId: 2, Limit: null, Delay: null },
      { Id: 25, Name: 'Corridor 3', Icon: 'fa fa-exclamation', IsSelected: null, SubId: 3, Limit: null, Delay: null }
    ]
  },
  {
    Id: 26,
    Name: 'Late Betting',
    Icon: 'fa fa-hand-point-up',
    Color: 'c-26',
    Limit: null,
    Delay: null,
    IsSelected: null,
    SubId: null,
    isChild: false,
    SelectedGameStyleId: null,
    PlayerSubCategories: [
      { Id: 26, Name: 'Late Betting 1', Icon: 'fa fa-hand-point-up', IsSelected: null, SubId: 1, Limit: null, Delay: null },
      { Id: 26, Name: 'Late Betting 2', Icon: 'fa fa-hand-point-up', IsSelected: null, SubId: 2, Limit: null, Delay: null },
      { Id: 26, Name: 'Late Betting 3', Icon: 'fa fa-hand-point-up', IsSelected: null, SubId: 3, Limit: null, Delay: null }
    ]
  },
  {
    Id: 27,
    Name: 'Strong Opinion',
    Icon: 'fa fa-laptop',
    Color: 'c-27',
    Limit: null,
    Delay: null,
    IsSelected: null,
    SubId: null,
    isChild: false,
    SelectedGameStyleId: null,
    PlayerSubCategories: [
      { Id: 27, Name: 'Strong Opinion 1', Icon: 'fa fa-laptop', IsSelected: null, SubId: 1, Limit: null, Delay: null },
      { Id: 27, Name: 'Strong Opinion 2', Icon: 'fa fa-laptop', IsSelected: null, SubId: 2, Limit: null, Delay: null },
      { Id: 27, Name: 'Strong Opinion 3', Icon: 'fa fa-laptop', IsSelected: null, SubId: 3, Limit: null, Delay: null }
    ]
  },
  {
    Id: 34,
    Name: 'Bot Arbitrage',
    Icon: 'fa fa-plug',
    Color: 'c-34',
    Limit: null,
    Delay: null,
    IsSelected: null,
    SubId: null,
    isChild: false,
    SelectedGameStyleId: null,
    PlayerSubCategories: [
      { Id: 34, Name: 'Bot Arbitrage 1', Icon: 'fa fa-plug', IsSelected: null, SubId: 1, Limit: null, Delay: null },
      { Id: 34, Name: 'Bot Arbitrage 2', Icon: 'fa fa-plug', IsSelected: null, SubId: 2, Limit: null, Delay: null },
      { Id: 34, Name: 'Bot Arbitrage 3', Icon: 'fa fa-plug', IsSelected: null, SubId: 3, Limit: null, Delay: null }
    ]
  },
  {
    Id: 35,
    Name: 'Bonus Hunter',
    Icon: 'fa fa-bold',
    Color: 'c-35',
    Limit: null,
    Delay: null,
    IsSelected: null,
    SubId: null,
    isChild: false,
    SelectedGameStyleId: null,
    PlayerSubCategories: [
      { Id: 35, Name: 'Bonus Hunter 1', Icon: 'fa fa-bold', IsSelected: null, SubId: 1, Limit: null, Delay: null },
      { Id: 35, Name: 'Bonus Hunter 2', Icon: 'fa fa-bold', IsSelected: null, SubId: 2, Limit: null, Delay: null },
      { Id: 35, Name: 'Bonus Hunter 3', Icon: 'fa fa-bold', IsSelected: null, SubId: 3, Limit: null, Delay: null }
    ]
  }
];

export const PartnerStatus = {
  true: {
    name: 'Existing',
    color: '#ff6159'
  },
  false: {
    name: 'Imported',
    color: '#27ca41'
  }
};

export const alertStatuses = {
  'Created': {
    name: 'Created',
    color: '#3ec3ef'
  },
  0: {
    name: 'Created',
    color: '#3ec3ef'
  },
  'Pending': {
    name: 'Pending',
    color: '#ffca5f'
  },
  1: {
    name: 'Pending',
    color: '#ffca5f'
  },
  'Confirmed': {
    name: 'Confirmed',
    color: '#46904a'
  },
  2: {
    name: 'Confirmed',
    color: '#46904a'
  },
  'Rejected': {
    name: 'Rejected',
    color: '#e03f33'
  },
  3: {
    name: 'Rejected',
    color: '#e03f33'
  },
  'Prolonged': {
    name: 'Prolonged',
    color: '#e03f33'
  },
  4: {
    name: 'Prolonged',
    color: '#e03f33'
  },
  'Genarated Alert': {
    name: 'Genarated Alert',
    color: '#e03f33'
  },
  5: {
    name: 'Genarated Alert',
    color: '#e03f33'
  },
  'Read': {
    name: 'Read',
    color: '#46904a'
  },
  6: {
    name: 'Read',
    color: '#46904a'
  },
  'ReCreate': {
    name: 'ReCreate',
    color: '#3ec3ef'
  },
  7: {
    name: 'ReCreated',
    color: '#3ec3ef'
  },
  'Reviewed': {
    name: 'Reviewed',
    color: '#46904a'
  },
  'ReCreated': {
    name: 'ReCreated',
    color: '#3ec3ef'
  },
  8: {
    name: 'Resolved',
    color: '#77bd7e'
  },
  'Resolved': {
    name: 'Resolved',
    color: '#77bd7e'
  },
  9: {
    name: 'Processing',
    color: '#ffca5f'
  },
  'Processing': {
    name: 'Processing',
    color: '#ffca5f'
  },
  'Reviewing': {
    name: 'Reviewing',
    color: '#ffca5f'
  },
  10: {
    name: 'Reviewing',
    color: '#ffca5f'
  },
  'Dismiss': {
    name: 'Dismissed',
    color: '#993300'
  }
};

export const triggerTypes = {
  0: 'Payment',
  1: 'Last Name & Birth Date & Address',
  2: 'Last Name & Birth Date & Email',
  4: 'Birth Date & Address & Email',
  5: 'Birth Date & Email',
  6: 'Address & Email',
  7: 'Birth Date & First Name & Last Name',
  8: 'Email and Phone',
  9: 'Cookie and Fingerprint',
  10: 'Zip Code',
  11: 'Ip'
};

export const alertTypes = {
  1: {
    name: 'MultiAccount',
    tabUri: 'fraud-monitor/multiAccount',
    type: fraudMonitor.key
  },
  2: {
    name: 'LinkedAccount',
    tabUri: 'fraud-monitor/linkedAccount',
    type: fraudMonitor.key
  },
  3: {
    name: 'Monitoring',
    tabUri: 'info-monitor/monitoring',
    type: infoMonitor.key
  },
  6: {
    name: 'Financial',
    tabUri: 'info-monitor/financial',
    type: infoMonitor.key
  },
  9: {
    name: 'Game Style',
    tabUri: 'fraud-monitor/gameStyle',
    type: fraudMonitor.key
  },
  10: {
    name: 'Additional',
    tabUri: 'info-monitor/additional',
    type: infoMonitor.key
  },
  15: {
    name: 'False Game Style',
    tabUri: 'info-monitor/falseGameStyle',
    type: infoMonitor.key
  }
};

export const alertTypesEnum = {
  multiAccount: 1,
  linkedAccount: 2,
  gameStyle: 9,
  additional: 10,
  financial: 6,
  monitoring: 3,
  falseGameStyle: 15
};

export const alertActionTypes = {
  add: 0,
  edit: 1,
  remove: 2
};

export const defaultPaginationParams = {
  count: 20
};

export const additionalCategoryTagsConfig = {
  women: {
    color: '#ef5d8f',
    name: 'Women'
  },
  age: {
    color: '#47b4a9',
    name: 'Age'
  },
  country: {
    color: '#5fb4f6',
    name: 'Country'
  },
  custom: {
    color: '#b763c6',
    name: 'Custom'
  }
};

export const financialTransactionsStates = {
  Paid: iconColors.green4IconColor,
  Answered: iconColors.blue3IconColor,
  New: iconColors.blue4IconColor,
  Canceled: iconColors.purpleIconColor,
  Rejected: iconColors.redIconColor,
  Pending: iconColors.orange2IconColor
};

export const arrowPositionTypes = {
  top: 'top',
  center: 'center',
  bottom: 'bottom'
};

export const genderEnum = {
  0: 'Undefined',
  1: 'Male',
  2: 'Female'
};

export const productSettingsTypes = {
  lcrmtSettingType: 4,
  srmtSettingType: 5,
  amlLicense: 2,
  amlPartner: 1
};

export const srmtNotificationSettingsUrl = {
  12: 'fraud-monitor/linkedAccount',
  13: 'fraud-monitor/multiAccount',
  14: 'fraud-monitor/gameStyle'
};

export const arrowPositions = (top) => ({
  [arrowPositionTypes.top]: {
    topArrow: 18,
    topPopup: -12 + top
  },
  [arrowPositionTypes.center]: {
    topArrow: 117,
    topPopup: -110 + top
  },
  [arrowPositionTypes.bottom]: {
    topArrow: 215,
    topPopup: -213 + top
  }
});

export const settings = {
  columnType: {
    possible: 'Possible',
    current: 'Current',
    ordering: 'Ordering'
  },
  arrowType: {
    below: 'below',
    left: 'left'
  }
};

export const ChangeTypes = {
  1: 'Added',
  2: 'Modified',
  3: 'Deleted'
};

export const CategoryTypes = {
  1: 'Financial',
  2: 'Game Style',
  3: 'Additional'
};

export const CategoryTypesEnum = {
  Financial: 1,
  GameStyle: 2,
  Additional: 3
};

export const FilterKeys = {
  betType: 'BetType',
  verificationStatus: 'verificationStatus',
  currency: 'Currency',
  ClosureReason: 'ClosureReason',
  combinationType: 'CombinationType',
  rouletteTable: 'RouletteTable',
  activeRouletteTable: 'ActiveRouletteTable',
  rouletteStatisticsTypes: 'RouletteStatisticsTypes',
  partners: 'EnabledPartner',
  srmtPartners: 'SRMTEnabledPartner',
  activePartners: 'ActivePartnerList',
  partnerTags: 'PartnerTag',
  triggers: 'TriggerName',
  paymentSystems: 'PaymentSystem',
  partnerType: 'PartnerType',
  topPriority: 'TopPriority',
  isTopPriority: 'IsTopPriority',
  licenseType: 'LicenseType',
  categoryType: 'CategoryType',
  playerStatus: 'PlayerStatus',
  financeCategory: 'FinanceCategory',
  gameStyleCategory: 'GameStyleCategory',
  registrationIp: 'Registration IP',
  additionalCategory: 'AdditionalCategory',
  changeType: 'ChangeType',
  product: 'Product',
  riskManagement: 'RiskManagement',
  operatingPartner: 'OperatingPartner',
  alertType: 'AlertType',
  alertStatus: 'AlertStatus',
  fraudAlertType: 'FraudAlertType',
  fraudAlertStatus: 'FraudAlertStatus',
  fraudAlertTriggers: 'FraudTrigger',
  exclusionType: 'ExclusionType',
  transactionTypes: 'TransactionType',
  scoreRuleTypes: 'ScoreRuleTypes',
  scoreRiskCategories: 'ScoreRiskCategories',
  countries: 'Countries',
  amlPartners: 'AMLPartners',
  amlRules: 'AMLRules',
  frmtAmlCombineRules: 'FRMTAmlCombinedRules',
  frmtAmlRules: 'FRMTAmlRules',
  frmtP2PRules: 'FRMTP2PRules',
  FRMTSuspiciousRules: 'FRMTSuspiciousRules',
  singleThresholdAlertType: 'SingleThresholdAlertType',
  riskAssessmentRuleType: 'RiskAssessmentRuleType',
  multipleAccountAlertType: 'MultipleAccountAlertType',
  suspiciousAlertType: 'SuspiciousAlertType',
  amlRiskCategory: 'AmlRiskCategory',
  licenseTrigger: 'LicenseTrigger',
  clientSportsBookProfile: 'ClientSportsBookProfile',
  supportedLicenses: 'SupportedLicenses',
  lcrmtPartner: 'lcrmtPartner',
  lcrmtEnabledPartner: 'LCRMTEnabledPartner',
  lcrmtProvider: 'lcrmtProvider',
  lcrmtGameType: 'lcrmtGameType',
  gameCategory: 'GameCategory',
  game: 'Game',
  gameType: 'GameType',
  selection: 'Selection',
  gameTypes: 'GameTypes',
  table: 'Table',
  studioTable: 'StudioTable',
  crmtEnabledPartner: 'CRMTEnabledPartner',
  studio: 'LiveCasinoStudios',
  hall: 'LiveCasinoHales',
  shuffler: 'LiveCasinoShufflers',
  lcrmtGame: 'lcrmtGame',
  lcrmtTable: 'lcrmtTable',
  lcrmtBetType: 'lcrmtBetType',
  gamingMarginAlertGameCategory: 'GamingMarginAlertGameCategory',
  gamingMarginAlertGameType: 'GamingMarginAlertGameType',
  gamingMarginAlertSelection: 'GamingMarginAlertSelection',
  customAlertPartners: 'CustomAlertPartners',
  customAlertSettings: 'CustomAlertSettings',
  customAlertGroups: 'CustomAlertGroups',
  customGameAlertSettings: 'CustomGameAlertSettings',
  alertStatuses: 'alertStatuses',
  ggrGameCategory: 'GGRGameCategory',
  cumulativeLossGameCategories: 'CumulativeLossGameCategories',
  customGameCategories: 'CustomGameCategories',
  providers: 'Providers',
  games: 'Games',
  ggrCategoryAllGames: 'GGRCategoryAllGames',
  sportTypes: 'CustomSportTypes',
  competitions: 'CustomCompetitions'
};
