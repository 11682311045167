import React from 'react';
import Link from 'react-router-dom/es/Link';

import { permissions } from 'config/settings/permissions';
import settings from 'config/constants';

const products = settings.PRODUCTS;

const { path } = products.CRMT;

export default [
  {
    id: `/${path}/alert-monitor`,
    title: 'Alert Monitor',
    icon: 'bc-icon-bell-outline',
    permission: permissions.CRMTAlertMonitor,
    data: [
      {
        id: `/${path}/alert-monitor/game-anomalies`,
        title: <Link to={`/${path}/alert-monitor/game-anomalies`}>Game Anomalies</Link>,
        permission: permissions.CRMTAlertMonitor
      },
      {
        id: `/${path}/alert-monitor/multiAccount`,
        title: <Link to={`/${path}/alert-monitor/multiAccount`}>Multi Account</Link>,
        permission: permissions.CRMTAlertMonitor
      }
    ]
  },
  {
    id: `/${path}/players`,
    title: <Link className="route_link" to={`/${path}/players`}>Players</Link>,
    icon: 'bc-icon-players-rmt-36',
    permission: permissions.CRMTBox
  },
  {
    id: `/${path}/player-groups`,
    title: <Link className="route_link" to={`/${path}/player-groups`}>Players Group</Link>,
    icon: 'bc-icon-players',
    permission: permissions.CRMTPlayersGroup
  },
  {
    id: `/${path}/settings`,
    title: 'Settings',
    icon: 'bc-icon-settings',
    permission: permissions.CRMTSettings,
    data: [
      {
        id: `/${path}/settings/alert-settings`,
        title: <Link to={`/${path}/settings/alert-settings`}>Alert Settings</Link>,
        permission: permissions.CRMTSettings
      },
      {
        id: `/${path}/settings/trigger-settings`,
        title: <Link to={`/${path}/settings/trigger-settings`}>Trigger Settings</Link>,
        permission: permissions.CRMTSettings
      }
    ]
  }
];
